<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.wordpressConfig.title') }}</h4>
        </v-col>
        <v-col class="page-title__filter-controls">
          <data-iterator-filter-controls
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>
          <v-btn
            @click="showCreateModal"
            :large="!$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
          >
            <v-icon>$plus</v-icon>{{ $t('button.config.create') }}
          </v-btn>
          <v-dialog
            :persistent="modalOptions.persistent"
            v-model="modalOptions.open"
            ref="dialog"
            transition="custom-dialog-transition"
          >
            <div
              class="card-overlay"
              v-if="!modalOptions.persistent"
              @click="modalOptions.open = !modalOptions.open"
            />
            <div
              class="card-overlay"
              v-else
              @click="$refs.dialog.animateClick()"
            />
            <basic-modal
              style="width: 560px"
              :modalOptions="modalOptions"
              @modal-close="modalClose"
              :key="modalRender"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0">
      <v-row>
        <v-col cols="12">
          <configs-table
            :headers="headers"
            :items="items"
            :itemsLoading="itemsLoading"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            :page="page"
            :possibleItemsPerPage="[[8, 16, 24]]"
            :searchTerm="searchTerm"
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            @update:check-all="handleSelectAll"
            @update:checked-items="handleItemCheck"
            @update:itemsPerPage="changePerPage"
            @update:page="changePage"
            @update:sortBy="changeSortBy"
            @update:sortDesc="changeSortDesc"
            @action-button-edit="showEditModal"
            @action-button-delete="showDeleteModal"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { fetchActiveInstanceConfig } from "../../../router/fetchData";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";
import ActionModalMixin from "../../../mixins/ActionModalMixin";
import ConfigActionsMixin from "../../../mixins/ConfigActionsMixin";

import DataIteratorFilterControls from "../../../components/dataIterator/DataIteratorFilterControls.vue";
import ConfigsTable from "../../../components/dataIterator/tables/ConfigsTable.vue";
import BasicModal from "@/components/modal/BasicModal";

export default {
  components: {
    DataIteratorFilterControls,
    ConfigsTable,
    BasicModal,
  },
  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    ActionModalMixin,
    ConfigActionsMixin,
  ],
  data: function () {
    return {
      sortBy: {
        text: this.$t('table.header.name'),
        align: "start",
        value: "name",
        sortable: true,
      },
      headers: [
        {
          text: this.$t('table.header.name'),
          align: "start",
          value: "name",
          sortable: true,
          searchable: true,
        },
        {
          text: this.$t('table.header.value'),
          value: "value",
          sortable: true,
          searchable: true,
        },
        {
          text: this.$t('table.header.type'),
          value: "type",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      checkedItems: [],
      items: [],
      allItems: [],
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      fetching: true,
      instanceId: this.$route.params.id,
      modalRender: 0,
      modalOptions: {},
    };
  },
  mounted() {
    this.reloadConfig();
  },
  methods: {
    reloadConfig() {
      this.itemsLoading = true;
      fetchActiveInstanceConfig(this.instanceId).then((response) => {
        this.itemsLoading = false;
        if (response.error) {
          // console.error("ERROR: ", response.error);
          return;
        }
        this.allItems = response.data;
        this.fetching = false;
        this.loadConfig();
      });
    },
    loadConfig() {
      this.itemsLoading = true;
      let items = [...this.allItems];

      // filtering
      if (this.searchTerm) {
        items = items.filter((item) => {
          for (const header of this.headers) {
            if (header.searchable) {
              if (
                item[header.value]
                  .toString()
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        });
      }

      this.itemsTotal = items.length;

      // sorting
      items = items.sort((i1, i2) => {
        return (
          i1[this.sortBy.value]
            .toString()
            .localeCompare(i2[this.sortBy.value].toString()) *
          (this.sortDesc ? -1 : 1)
        );
      });

      // paging
      items = items.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );

      this.items = items;
      this.itemsLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadConfig();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadConfig();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadConfig();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadConfig();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadConfig();
    },
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>
